import * as React from 'react';
import Layout from '@components/layout';
import { Link } from 'gatsby';
import TheEnd from '@components/theend';
import { useTranslation } from 'react-i18next';
import Box from '@common/box';
import { StaticImage } from 'gatsby-plugin-image';
import EndMark from '@images-svg/endmark.svg';
import styled from 'styled-components';

const StyledEndMark = styled(EndMark)`
  && {
    margin: 0 auto;
    box-sizing: content-box;
    padding: 2em;
    display: block;
    width: 100px;
  }
`;

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <Layout titleOverride="Kotikäyttöiset silmänpainemittarit" descriptionOverride="Globaali terveyspalvelu iCarelle">
      <Box>
        <header>
          <iframe
            width="336"
            height="189"
            src="https://www.youtube.com/embed/bJ0V4wmHcbY?si=jySKQUJ7KMlVywGu"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </header>
        <section>
          <p className="text-2xl">
            <i>Icare Finland Oy</i> on kotikäyttöisten silmänpainemittareiden valmistajana alansa edelläkävijä. Tietoturvallisen palvelun varmistamiseksi heidän
            yhteistyökumppaninsa Taitopilvi hoitaa alustan teknisen ylläpidon, minkä ansiosta asiakkaat voivat vaivattomasti toimittaa tulokset Icare Clinic
            -pilviohjelmiston kautta lääketieteen ammattilaisten ulottuville. Ainoana kotikäyttöisten silmänpainemittareiden valmistajana Icare Finlandin
            markkinat ovat globaalit, ja tuotteita myydäänkin yli 80 maassa ympäri maailmaa. Kotikäyttöinen mittaus mahdollistaa entistä tiiviimmän hoitosuhteen
            potilaan ja lääkärin välillä, sillä se antaa myös laajemman kuvan kokonaistilanteesta.
          </p>
          <p>
            – Silmänpaineiden ympärivuorokautinen seuranta on tärkeää, koska silmänpainepiikkejä ei useinkaan saada kiinni potilaan tullessa klinikkakäynnille.
            Näiden laitteiden avulla he voivat tehdä mittauksia useasti päivässä ja lähettää sitten tiedot helposti pilvialustan kautta eteenpäin, kuvailee
            Icare Finlandin ohjelmistotuotepäällikkö Vesa Hakkarainen.
          </p>
          <p>
            Taitopilven ylläpitämä Icare Clinic -pilviohjelmisto takaa kotikäyttöisen mittauksen toimivuuden. Potilaan näkökulmasta tiedonsiirto toteutuu
            vaivattomasti kytkemällä laite joko kännykkään tai tietokoneeseen, ja kaikki muu hoituu sovelluksen kautta automaattisesti.
          </p>
          <p>– Potilas ja ammattilainen näkevät mittaustulokset pilvessä, joten se on keskeinen osa koko järjestelmää, Hakkarainen jatkaa.</p>
          <h3>AWS-ympäristö vastaa kansainvälisiin vaatimuksiin</h3>
          <p>
            Icare Clinic suunniteltiin tiiviissä yhteistyössä Taitopilven kanssa. Käytössä oleva Amazon Web Services -alusta vastaa myös kansainvälisten
            markkinoiden tarpeisiin.
          </p>
          <p>
            – Tärkeintä meille oli löytää ympäristö, joka on mahdollisimman kattava ympäri maailmaa. Tiedot eivät voi olla missä tahansa, vaan niiden täytyy
            olla saatavilla myös paikallisesti, Hakkarainen kertoo.
          </p>
          <p>Koska kyse on sensitiivisistä potilastiedoista, yhteyksien täytyy toimia saumattomasti ja datan säilyä hyvin suojattuna.</p>
          <p>
            – Tietoihin on päästävä helposti käsiksi, mutta niin, että ne pysyvät tallessa eivätkä huku mihinkään. On ensiarvoisen tärkeää, ettei kukaan pääse
            asiattomasti muokkaamaan tai kopioimaan potilastietoja, Hakkarainen painottaa.
          </p>
          <h3>Asiantuntijuus takaa sujuvan yhteistyön</h3>
          <p>
            Matkan varrella Icare Finlandin ja Taitopilven välille on syntynyt luottamuksellinen yhteistyösuhde. Hakkarainen arvostaa Taitopilven ammattilaisten
            asiantuntemusta ja AWS-järjestelmään liittyvää tietotaitoa sekä sitä, että heiltä on aina tarpeen tullen saanut ideoita ja kehitysehdotuksia. Näin
            palvelua on pystytty kehittämään entistä paremmaksi.
          </p>
          <p>– Tässä meillä on yhteistyökumppani, joka pystyy tarjoamaan meille tämän palvelun niin kuin sen haluamme, Hakkarainen kiittelee vielä lopuksi.</p>
          <div className="flex justify-center pt-5">
            <StaticImage src="../images/icare-revenio.jpg" alt="iCare - silmänpainemittaus kotona" layout="constrained" width={1136} />
          </div>
          <StyledEndMark />
        </section>
      </Box>
      <TheEnd />
    </Layout>
  );
}
